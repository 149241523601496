import React, { useEffect, useState } from 'react'
import './index.scss'

import { graphql, Link } from 'gatsby'
import { SanityProjectsPage } from '@utils/types'
import loadable from '@loadable/component'
import SEO from '@components/seo'
import useResizeWidth from '@hooks/useResizeWidth'
import { Dropdown, Image } from 'react-bootstrap'
//@ts-ignore
import DropdownCarrot from '@images/svg/dropdown.svg'
const ButtonComponent = loadable(() => import('@components/buttonComponent'))
const ProjectGallery = loadable(() => import('@components/projectGallery'))

type Props = {
  data: {
    sanityProjects: SanityProjectsPage
  }
  location: Location
}

const ProjectPage = ({ data: { sanityProjects }, location }: Props) => {
  const [category, setCategory] = useState('residential')
  const avaibleCategories = ['residential', 'commercial']
  const [projects, setProjects] = useState([])
  const { width } = useResizeWidth()
  const metadata = {
    location: location.href,
    ...sanityProjects?.seo,
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search?.substring(1) || '')
    let cat = params.get('category')
    if (cat) {
      setCategory(cat)
    } else {
      setCategory('residential')
      cat = 'residential'
    }
    setProjects(
      sanityProjects?.projects?.filter(
        (project) => project?.category?.toLowerCase() === cat?.toLowerCase(),
      ),
    )
  }, [location])

  return (
    <>
      <SEO {...metadata} />
      <div id="gallery" className="projects-gallery">
        <div className="gallery-header">
          <h1>Projects</h1>
          {width > 700 ? (
            <div className="gallery-filter">
              {avaibleCategories.map((item, index) => (
                <ButtonComponent
                  key={index}
                  className={category === item ? 'active-link' : ''}
                  button={{
                    title: item,
                    slug: {
                      current: `?category=${item}`,
                    },
                    type: 'secondary',
                  }}
                />
              ))}
            </div>
          ) : (
            <Dropdown className="dropdown">
              <Dropdown.Toggle
                className="dropdown-toggle"
                variant="none"
                id="dropdown-basic"
              >
                {category}
                <Image src={DropdownCarrot} />
              </Dropdown.Toggle>

              <Dropdown.Menu className="hwh-lateral-dropdown-menu">
                {avaibleCategories.map((item, index) => (
                  <Dropdown.Item as={Link} to={`?category=${item}`} key={index}>
                    {item}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        {projects && <ProjectGallery content={projects} />}
      </div>
    </>
  )
}

export default ProjectPage

export const pageQuery = graphql`
  query {
    sanityProjects(_id: { in: ["drafts.projectsPage", "projectsPage"] }) {
      seo {
        description
        keywords
        image
        title
        twitterCard
        twitterCreator
        twitterSite
      }
      slug {
        current
      }
      projects {
        seo {
          description
          keywords
          image
          title
          twitterCard
          twitterCreator
          twitterSite
        }
        slug {
          current
        }
        category
        title
        mainImage {
          alt
          asset {
            gatsbyImageData(
              placeholder: BLURRED
              height: 800
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        hoverImage {
          alt
          asset {
            gatsbyImageData(
              placeholder: BLURRED
              height: 800
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
